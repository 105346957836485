
import { DeviseType, PaymentMode } from '@/helpers/enum'
import { formatAmount, formatDate } from '@/helpers/functions'
import { TransactionDTO, TransactionDTOTransactionStatusEnum, TransactionDTOTransactionTypeEnum } from '@/models'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'TransactionItem',
  props: {
    transaction: {
      type: Object as PropType<TransactionDTO>,
      required: true
    }
  },
  computed: {
    formatDate() {
      return formatDate
    }
  },
  data() {
    return {
      deviseType: DeviseType,
      transactionType: TransactionDTOTransactionTypeEnum,
      transactionStatus: TransactionDTOTransactionStatusEnum,
      paymemtMode: PaymentMode,
      formatAmount
    }
  },
  methods: {
    getFirstLetterOfReceiver: function (receiver: any) {
      if (receiver) {
        return receiver.firstName.charAt(0) + receiver.lastName.charAt(0)
      }
    },
    getPhoneNumber: function (receiver: any) {
      if (receiver) {
        return receiver.phones[0].phoneNumber
      }
    },
    provider: function (transaction: TransactionDTO, number?: boolean): string {
      const [billNumber, provider] = transaction.paymentMode?.split('_') ?? []
      if (number) {
        return billNumber
      }
      return provider
    },
    getFirstLetterOfProvider: function (transaction: TransactionDTO) {
      const [, provider] = transaction.paymentMode?.split('_') ?? []
      return provider.charAt(0).toUpperCase()
    }
  }
})
